
import {
    CalendarOutlined,
    DownloadOutlined,
    PlusOutlined
} from "@ant-design/icons-vue";

export default {
    name: "AccountCharge",
    components: {
        CalendarOutlined,
        DownloadOutlined,
        PlusOutlined
    },
    data() {
        return {
            url: "/admin/charge/list",
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 财务操作弹窗
            opreationAccountVisible: false,
            loading: false,
            // 财务表单
            form: {
                action_type: 1
            },
            // 财务表单规则
            rules: {
                action_type: [{
                    required: true,
                    message: this.$t("share.require"),
                    trigger: "change",
                    type: "number"
                }],
                user_account_code: [{
                    required: true,
                    message: this.$t("share.require"),
                    trigger: "change"
                }],
                charge: [{
                    required: true,
                    message: this.$t("share.require"),
                    trigger: "blur",
                    type: "number"
                }],
                remark: [{
                    required: true,
                    message: this.$t("share.require"),
                    trigger: "blur"
                }]
            },
            // 搜索表单是否展开
            searchExpand: true,
            // 费用名称列表
            charge_name_list: [],
            // 导出费用
            exportLoading: false,
            // 财务类型
            record_type_text: -1
        };
    },
    computed: {
        columns() {
            const new_col = {
                "fhjy.lbl.morelink56.com": [{
                    title: this.$t("订单号"),
                    dataIndex: "reference2",
                    width: 200
                }],
                "first-star.lbl.morelink56.com": [{
                    title: this.$t("订单号"),
                    dataIndex: "reference2",
                    width: 200
                }],
                default_col: [{
                    title: this.$t("订单号"),
                    dataIndex: "order_po_number",
                    width: 200,
                    slots: {
                        customRender: "po_number"
                    }
                },]
            }

            return [{
                key: "index",
                width: 48,
                customRender: ({
                    index
                }) =>
                    this.$refs.table.tableIndex + index
            },
            {
                title: this.$t("客户代码"),
                dataIndex: "user_account_code",
                sorter: true,
                width: 100
            },
            {
                title: this.$t("登录账号"),
                dataIndex: "user_account",
                sorter: true,
                width: 120
            },
            {
                title: this.$t("公司名称"),
                dataIndex: "user_company_name",
                width: 100
            },
            ...(new_col[this.$setting.env] || new_col['default_col']),
            {
                title: this.$t("费用名称"),
                dataIndex: "charge_name",
                width: 200
            },
            {
                title: this.$t("备注"),
                dataIndex: "remark",
                className: "remark-max"
            },
            {
                title: this.$t("费用"),
                dataIndex: "charge",
                width: 200,
                slots: {
                    customRender: "charge"
                }
            },
            {
                title: this.$t("余额"),
                dataIndex: "balance",
                width: 120,
                customRender: ({
                    record
                }) =>
                    `${record.balance} ${record.currency}`
            },
            {
                title: this.$t("状态"),
                dataIndex: "status",
                width: 120,
                slots: {
                    customRender: "status"
                }
            },
            {
                title: this.$t("交易时间"),
                dataIndex: "created",
                width: 150
            }
            ];
        }
    },
    methods: {
        // 提交财务操作
        submit() {
            this.$refs.form.validate().then(() => {
                this.loading = true;

                this.$http
                    .post("/admin/charge/update", this.form)
                    .then(res => {
                        const data = res.data;
                        if (data.code == 0) {
                            this.opreationAccountVisible = false;
                            this.form = {
                                action_type: 1
                            };
                            this.reload();
                            this.$message.success("操作成功");
                        } else {
                            this.$message.error(data.msg);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            });
        },
        // 重置表单
        resetForm() {
            this.form = {
                action_type: 1
            };
        },
        /* 财务操作 */
        opreationAccount() {
            this.opreationAccountVisible = true;
        },
        // 获取费用名称
        get_charge_name() {
            this.$http.get("/admin/charge/get_charge_name").then(res => {
                if (res.data.code == 0) this.charge_name_list = res.data.data;
            });
        },
        /* 搜索 */
        reload() {
            if (this.record_type_text != -1) {
                this.where.record_type_text = this.record_type_text;
            } else {
                delete this.where.record_type_text;
            }
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 导出Excel
         */
        exportExcel() {
            this.exportLoading = true;

            this.$http
                .get(`/admin/charge/export`, {
                    params: this.where
                })
                .then(res => {
                    this.exportLoading = false;

                    if (res.data.code === 0) {
                        const blob = this.base64ToBlob(
                            res.data.data,
                            "application/vnd.ms-excel"
                        );
                        const fileName = `Charges-${new Date().getTime()}.xls`;

                        if ("download" in document.createElement("a")) {
                            // 非IE下载
                            const el = document.createElement("a");

                            el.download = fileName;
                            el.style.display = "none";
                            el.href = URL.createObjectURL(blob);
                            document.body.appendChild(el);

                            el.click();

                            URL.revokeObjectURL(el.href);
                            document.body.removeChild(el);
                        } else {
                            // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    } else {
                        this.$message.error(res.data.msg);
                    }
                })
                .catch(e => {
                    this.exportLoading = false;
                    this.$message.error(e.message);
                });
        },
        base64ToBlob(text, content_type) {
            var byteString = atob(text);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], {
                type: content_type
            });
        }
    },
    async created() {
        this.get_charge_name();
    }
};
